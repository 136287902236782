import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { APP_REQUEST } from '../helpers/request';
import { useAuth } from '../context/AuthProvider';
import SignUpForm from '../components/account/sign-up-form';
import SignupDisabled from '../components/SignupDisabled';
import BackButton from '../components/BackButton';
import { FEATURES } from '../constants';
import styles from '../components/loginStyles';

const SignUp = () => {
  const { loading } = useAuth();

  if (loading) {
    return <CircularProgress />;
  }

  if (!FEATURES.ENABLE_SIGNUP) {
    return (
      <div id="sign-up-page" className="auth-form form-container">
        <Box sx={styles.login.back}>
          <BackButton />
        </Box>
        <SignupDisabled />
      </div>
    );
  }

  return (
    <div id="sign-up-page" className="auth-form form-container">
      <Box sx={styles.login.back}>
        <BackButton />
      </Box>
      <SignUpForm
        onSignInClick={() => {
          APP_REQUEST.redirect('sign-in');
        }}
      />
    </div>
  );
};

export default SignUp;
