import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthProvider';
import AccountForm from '../components/form/account';
import { UserCheck, Loader } from 'lucide-react';

import { isValidPassword, validPasswordConditions } from '../helpers/validator';
import { API_REQUEST } from '../helpers/request';
import { FEATURES } from '../constants';

const AccountActivationPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const token = params?.token;

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const [userEmail, setUserEmail] = useState('');
  const [isValidating, setIsValidating] = useState(true);
  const [isTokenValid, setIsTokenValid] = useState(false);

  const { loggedIn } = useAuth();

  if (loggedIn) {
    navigate('/dashboard');
    return null;
  }

  // Verify token and get user email
  useEffect(() => {
    if (!token) {
      setIsValidating(false);
      return;
    }

    API_REQUEST.get(`/account/verify-activation-token/${token}`)
      .then((res) => {
        setUserEmail(res.data.email);
        setIsTokenValid(true);
        setIsValidating(false);
      })
      .catch(() => {
        setError(
          <>
            This activation link is invalid or has expired. Please contact
            support at{' '}
            <a
              href={`mailto:${FEATURES.CONTACT_EMAIL}`}
              className="text-green-600 hover:text-green-700 font-medium underline"
            >
              {FEATURES.CONTACT_EMAIL}
            </a>
            .
          </>
        );
        setIsTokenValid(false);
        setIsValidating(false);
      });
  }, [token]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isValidPassword(password)) {
      setError(validPasswordConditions);
      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    setIsSubmitting(true);

    API_REQUEST.post('/account/activate', {
      token,
      password: password,
    })
      .then(() => {
        setIsSubmitting(false);
        setError(null);
        setSuccess(true);

        // Start countdown timer
        const timer = setInterval(() => {
          setCountdown((prev) => {
            if (prev <= 1) {
              clearInterval(timer);
              navigate('/sign-in');
              return 0;
            }
            return prev - 1;
          });
        }, 1000);
      })
      .catch((err) => {
        setError(
          err.response?.data?.message || (
            <>
              An error occurred while activating your account. Please contact
              support at{' '}
              <a
                href={`mailto:${FEATURES.CONTACT_EMAIL}`}
                className="text-green-600 hover:text-green-700 font-medium underline"
              >
                {FEATURES.CONTACT_EMAIL}
              </a>
              .
            </>
          )
        );
        setIsSubmitting(false);
      });
  };

  const renderSuccess = () => (
    <div className="account-form">
      <div className="activation-success-container">
        <div className="success-icon">
          <UserCheck size={48} className="text-green-500" />
        </div>
        <h2 className="form-title green">Account Activated!</h2>
        <p className="success-description">
          Your account has been successfully activated. You can now sign in with
          your email and password.
        </p>
        <p className="redirect-notice">
          You will be redirected to the login page in {countdown} seconds
        </p>
        <div className="button-container">
          <button
            onClick={() => navigate('/sign-in')}
            className="button green-button"
            type="button"
          >
            Sign In Now
          </button>
        </div>
      </div>
    </div>
  );

  const renderError = () => (
    <div className="account-form">
      <div className="error-container">
        <h2 className="form-title">Invalid Activation Link</h2>
        {error ? (
          <div className="error-message">{error}</div>
        ) : (
          <p>
            No activation token provided. Please use the link from your
            activation email.
          </p>
        )}
      </div>
    </div>
  );

  const renderLoading = () => (
    <div className="account-form">
      <div className="loading-container text-center p-8">
        <Loader className="animate-spin h-8 w-8 text-green-500 mx-auto mb-4" />
        <p className="text-gray-600">Validating activation link...</p>
      </div>
    </div>
  );

  if (!token || (!isValidating && !isTokenValid)) {
    return renderError();
  }

  if (isValidating) {
    return renderLoading();
  }

  if (success) {
    return renderSuccess();
  }

  return (
    <div id="account-activation-page" className="account-form-page">
      <AccountForm
        title="Activate Your Account"
        subtitle={`Welcome ${userEmail}! Please set your password to activate your account.`}
        onSubmit={handleSubmit}
        submitButtonText="Activate Account"
        isSubmitting={isSubmitting}
        error={error}
      >
        <div className="input-container">
          <label htmlFor="password">Password:</label>
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            id="password"
            autoComplete="new-password"
          />
        </div>

        <div className="input-container">
          <label htmlFor="confirm-password">Confirm Password:</label>
          <input
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            type="password"
            id="confirm-password"
            autoComplete="new-password"
          />
        </div>
      </AccountForm>
    </div>
  );
};

export default AccountActivationPage;
