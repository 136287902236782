import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { APP_REQUEST, API_REQUEST } from '../helpers/request';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const authChecked = useRef(false);

  const register = async (
    email,
    acceptNewsletter = false,
    systemInfo = null
  ) => {
    try {
      const response = await API_REQUEST.post('auth/register', {
        email,
        acceptNewsletter,
        os: systemInfo?.os,
        browser: systemInfo?.browser,
      });

      if (response.status === 201) {
        const { user: userData, warning } = response.data;
        setUser(userData);
        return {
          success: true,
          message: response.data.message,
          warning,
        };
      }
    } catch (error) {
      return {
        success: false,
        error:
          error.response?.data?.message ||
          'Registration failed. Please try again.',
      };
    }
  };

  const login = async (email, password) => {
    try {
      const response = await API_REQUEST.post('auth/login', {
        email,
        password,
      });

      if (response.status === 200) {
        const { user: userData, warning } = response.data;
        setUser(userData);
        setLoggedIn(true);
        APP_REQUEST.redirect('dashboard');
        return {
          success: true,
          message: response.data.message,
          warning,
        };
      }
    } catch (error) {
      return {
        success: false,
        error:
          error.response?.data?.message || 'Login failed. Please try again.',
      };
    }
  };

  const logout = async () => {
    try {
      await API_REQUEST.get('auth/logout');
      setUser(null);
      setLoggedIn(false);
      await checkAuth(); // Verify logout succeeded
      APP_REQUEST.redirect('/intro');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const checkAuth = async () => {
    try {
      const response = await API_REQUEST.get('auth/check');
      const { isAuthenticated, user: userData } = response.data;
      setLoggedIn(isAuthenticated);
      setUser(userData || null);
      return isAuthenticated;
    } catch (error) {
      console.error('Error checking auth status:', error);
      setLoggedIn(false);
      setUser(null);
      return false;
    }
  };

  useEffect(() => {
    const initAuth = async () => {
      if (authChecked.current) return;
      authChecked.current = true;

      await checkAuth();
      setLoading(false);
    };

    initAuth();
  }, []);

  const redirectIfLoggedIn = (redirectPath = 'dashboard') => {
    if (!loading && loggedIn) {
      APP_REQUEST.redirect(redirectPath);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        register,
        login,
        logout,
        loading,
        loggedIn,
        redirectIfLoggedIn,
        setLoggedIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
