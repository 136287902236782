import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Grid, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UploadModal from '../components/quote/uploadModal';
import styles from '../components/muiStyles';
import { proqouImages } from '../constants/index';

const IntroPage = () => {
  const [showUploadModal, setShowUploadModal] = useState(false);

  return (
    <Box sx={styles.page}>
      <UploadModal show={showUploadModal} setShow={setShowUploadModal} />
      <Grid container>
        {/* Navigation Section */}
        <Grid item xs={12} sx={styles.navigation}>
          <Box sx={styles.navContainer}>
            <Box sx={styles.navigation.logoContainer}>
              <Box sx={styles.navigation.logo}>
                <img
                  src={`${proqouImages}/ai-round-logo.svg`}
                  alt="QSAI"
                  style={styles.navigation.logo.roundLogo}
                />
                <Typography sx={styles.navigation.logo.text}>
                  <span style={{ color: '#f4b82e' }}>QS</span>
                  <span style={{ color: '#4CC0AD' }}>AI</span>
                </Typography>
                <Typography sx={styles.navigation.logo.poweredBy}>
                  POWERED BY C-LINK
                </Typography>
              </Box>
            </Box>
            <Box sx={styles.navigation.buttonContainer}>
              <Button
                variant="contained"
                sx={{
                  ...styles.button,
                  ...styles.button.login,
                  ...styles.button.topLogin,
                }}
                component={Link}
                to="/sign-in"
              >
                Login
              </Button>
              <Button
                variant="contained"
                sx={{
                  ...styles.button,
                  ...styles.button.signup,
                  ...styles.button.topSignup,
                }}
                component={Link}
                to="/sign-up"
              >
                Sign up
              </Button>
            </Box>
          </Box>
        </Grid>

        {/* Header Section */}
        <Grid item xs={12} sx={styles.header}>
          <Typography variant="h1" sx={styles.header.h1}>
            Fast-track your subcontractor Tender Analysis with <br />
            <Typography component="span" sx={styles.header.h1.span}>
              {' '}
              AI-Powered{' '}
            </Typography>
            QS assistant
          </Typography>
          <Typography variant="h2" sx={styles.header.h2}>
            Upload up to 3 quotes and get an instant analysis of the best
            subcontractor, <br /> discrepancies and actionable insights <br />
            <Typography component="span" sx={styles.header.h1.span}>
              all in a few clicks.
            </Typography>
          </Typography>
          <Button
            variant="contained"
            className="page-button"
            sx={{
              ...styles.button,
              ...styles.button.signup,
              ...styles.button.largeSignup,
            }}
            component={Link}
            to="/sign-up"
          >
            Sign up for FREE*
          </Button>
        </Grid>

        {/* How It Works Section */}
        <Grid item sx={styles.works} xs={12}>
          <Box sx={styles.limitWidth}>
            <Typography sx={styles.works.title}>How it works</Typography>
            <Grid container sx={styles.howItWorksIcons}>
              {[
                {
                  title: 'Upload Quotes',
                  description:
                    'Drag and drop your quotes or select up to 3 quotes',
                },
                {
                  title: 'AI Driven Analysis',
                  description:
                    'Get a detailed comparison and find discrepancies',
                },
                {
                  title: 'Take Action',
                  description:
                    'Send requests for clarification or better pricing',
                },
              ].map((entry, index) => (
                <Grid xs={12} lg={4} key={index} sx={styles.icon}>
                  <img
                    className={`icon-${index + 1}`}
                    src={`${proqouImages}/how-${index + 1}.svg`}
                    alt={entry.title}
                    style={styles.icon.img}
                  />
                  <Typography variant="h6" sx={styles.icon.title}>
                    {entry.title}
                  </Typography>
                  <Typography variant="body2" sx={styles.icon.description}>
                    {entry.description}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>

        {/* Unlock Section */}
        <Grid sx={styles.unlock} item xs={12}>
          <Box sx={styles.limitWidth}>
            <Typography sx={styles.unlock.title} variant="h3">
              Unlock Powerful{' '}
              <Typography component="span">AI-Driven Tools </Typography>
              for Smarter Decisions
            </Typography>

            <Grid container>
              <Grid item xs={12} md={6} p={1}>
                <img
                  style={styles.unlock.image}
                  src={`${proqouImages}/unlock-1.svg`}
                  alt="Why Choose QSAI"
                />
              </Grid>
              <Grid sx={styles.unlock.textSection} item xs={12} md={6}>
                <Typography sx={styles.unlock.textSection.title}>
                  Best Subcontractor Comparison
                </Typography>
                <Typography sx={styles.unlock.textSection.subtitle}>
                  Simply upload your three chosen quotes in pdf/excel format
                </Typography>
              </Grid>
            </Grid>

            <Grid container sx={styles.unlock.rowReverse}>
              <Grid item xs={12} md={6}>
                <Typography
                  className="blue"
                  sx={styles.unlock.textSection.title}
                >
                  Spot Discrepancies Instantly
                </Typography>
                <Typography sx={styles.unlock.textSection.subtitle}>
                  In just a few minutes QSAI will do all the heavy lifting for
                  you
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <img
                  style={styles.unlock.image}
                  src={`${proqouImages}/unlock-2.svg`}
                  alt="Why Choose QSAI"
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} md={6}>
                <img
                  style={styles.unlock.image}
                  src={`${proqouImages}/unlock-3.svg`}
                  alt="Why Choose QSAI"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  className="orange"
                  sx={styles.unlock.textSection.title}
                >
                  Best Subcontractor Comparison
                </Typography>
                <Typography sx={styles.unlock.textSection.subtitle}>
                  Review your report to identify variations and additional value
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        {/* Why Choose Section */}
        <Grid item xs={12} sx={styles.choose}>
          <Box sx={styles.limitWidth}>
            <Grid container>
              <Grid item lg={5} xs={12}>
                <img
                  style={{ width: '100%', height: 'auto' }}
                  src={`${proqouImages}/ai-video.svg`}
                  alt="Walkthrough by Chris"
                />
              </Grid>
              <Grid item mt={5} lg={7} xs={12}>
                <Box>
                  <Typography sx={styles.choose.title}>
                    Why Choose{' '}
                    <Typography component="span" sx={styles.choose.title.span}>
                      QSAI
                    </Typography>
                  </Typography>
                  <Box sx={styles.choose.list} component="ul">
                    <Typography sx={styles.choose.list.listItem} component="li">
                      <CheckCircleIcon sx={styles.choose.list.checkmark} />
                      Streamline the subcontractor quote review process.
                    </Typography>
                    <Typography sx={styles.choose.list.listItem} component="li">
                      <CheckCircleIcon sx={styles.choose.list.checkmark} />
                      Identify and highlight any discrepancies between quotes.
                    </Typography>
                    <Typography sx={styles.choose.list.listItem} component="li">
                      <CheckCircleIcon sx={styles.choose.list.checkmark} />
                      Easily request clarification or better pricing from
                      subcontractors.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        {/* Download Section - Replacing the References Section */}
        <Grid item xs={12} className="download-section" sx={styles.header}>
          <Typography sx={styles.header.h1}>
            <Typography component="span" sx={styles.header.h1.span}>
              Enjoy full access to QSAI for free for 30 days{' '}
            </Typography>
            and then just £49.99pm excl VAT
          </Typography>
          <br />
          <Button
            className="page-button"
            variant="contained"
            sx={{
              ...styles.button,
              ...styles.button.signup,
              ...styles.button.largeSignup,
            }}
            onClick={() => setShowUploadModal(true)}
          >
            Sign up today
          </Button>
        </Grid>

        {/* Download Section */}
        <Grid item xs={12} className="download-section" sx={styles.header}>
          <Typography sx={styles.header.h1}>
            <Typography component="span" sx={styles.header.h1.span}>
              Looking for multiple licenses?
            </Typography>
          </Typography>
          <br />
          <Button
            className="page-button"
            variant="contained"
            component={Link}
            to="/contact-lloyd"
            sx={{
              ...styles.button,
              ...styles.button.login,
              ...styles.button.largeSignup,
            }}
          >
            Get in touch with our team
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default IntroPage;
