import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { useAuth } from '../../context/AuthProvider';
import { isValidEmail } from '../../helpers/validator';
import RegistrationSuccess from './RegistrationSuccess';
import styles from '../loginStyles';
import { Link as RouterLink } from 'react-router-dom';

const SignUpForm = ({
  title = 'Sign Up Now!',
  subtitle = 'Sign up for updates and new feature announcements',
  onSignInClick,
}) => {
  const { register } = useAuth();
  const [email, setEmail] = useState('');
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptNewsletter, setAcceptNewsletter] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [registrationComplete, setRegistrationComplete] = useState(false);
  const [systemInfo, setSystemInfo] = useState({
    os: '',
    browser: '',
  });

  useEffect(() => {
    // Detect OS
    const userAgent = window.navigator.userAgent;
    let os = 'Unknown OS';

    if (userAgent.indexOf('Win') !== -1) os = 'Windows';
    else if (userAgent.indexOf('Mac') !== -1) os = 'MacOS';
    else if (userAgent.indexOf('Linux') !== -1) os = 'Linux';
    else if (userAgent.indexOf('Android') !== -1) os = 'Android';
    else if (userAgent.indexOf('iOS') !== -1) os = 'iOS';

    // Detect Browser
    let browser = 'Unknown Browser';
    if (userAgent.indexOf('Chrome') !== -1) browser = 'Chrome';
    else if (userAgent.indexOf('Firefox') !== -1) browser = 'Firefox';
    else if (userAgent.indexOf('Safari') !== -1) browser = 'Safari';
    else if (userAgent.indexOf('Edge') !== -1) browser = 'Edge';
    else if (userAgent.indexOf('Opera') !== -1) browser = 'Opera';

    setSystemInfo({ os, browser });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Clear previous errors
    setErrors({});

    // Ensure email is trimmed
    const trimmedEmail = email.trim();
    setEmail(trimmedEmail);

    // Validate all fields
    const newErrors = {};

    if (!trimmedEmail) newErrors.email = 'Email is required';
    else if (!isValidEmail(trimmedEmail))
      newErrors.email = 'Invalid email address';

    if (!acceptTerms) {
      newErrors.terms = 'You must accept the terms and conditions';
    }

    // If there are any validation errors, stop submission
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // Proceed with submission only if validation passed
    try {
      setLoading(true);
      const result = await register(trimmedEmail, acceptNewsletter, systemInfo); // Add systemInfo

      if (result.success) {
        if (result.warning) {
          console.warn('Registration warning:', result.warning);
        }
        setRegistrationComplete(true);
      } else {
        setErrors({ submit: result.error });
      }
    } catch (error) {
      setErrors({
        submit:
          error.response?.data?.message ||
          'An unexpected error occurred during registration',
      });
    } finally {
      setLoading(false);
    }
  };

  if (registrationComplete) {
    return <RegistrationSuccess />;
  }
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={styles.login}
    >
      {loading && (
        <div className="load-overlay">
          <CircularProgress />
        </div>
      )}

      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          width: '100%',
          maxWidth: 400,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Typography sx={styles.login.title}>{title}</Typography>

        <Typography sx={styles.login.subtitle}>{subtitle}</Typography>

        <FormControl fullWidth>
          <InputLabel htmlFor="email" shrink>
            Email:
          </InputLabel>
          <TextField
            id="email"
            type="email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value.trim())}
            error={!!errors.email}
            helperText={errors.email}
            fullWidth
            autoComplete="email"
            placeholder="Enter your email"
            sx={styles.login.form.muiInput}
            disabled={loading}
          />
          {errors.email && <p className="error">{errors.email}</p>}
        </FormControl>

        <div className="form-checkbox">
          <label>
            <input
              name="terms"
              type="checkbox"
              checked={acceptTerms}
              onChange={(e) => setAcceptTerms(e.target.checked)}
              disabled={loading}
            />
            I accept the&nbsp;{'  '}
            <Link
              component={RouterLink}
              to="/terms"
              sx={{
                color: '#f4b82e',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
              onClick={(e) => e.stopPropagation()}
            >
              terms and conditions
            </Link>
          </label>
          {errors.terms && <p className="error">{errors.terms}</p>}
        </div>

        <div className="form-checkbox">
          <label>
            <input
              name="newsletter"
              type="checkbox"
              checked={acceptNewsletter}
              onChange={(e) => setAcceptNewsletter(e.target.checked)}
              disabled={loading}
            />
            Sign up for updates and new feature announcements
          </label>
        </div>

        <Grid container alignItems="center" sx={{ position: 'relative' }}>
          <Button
            sx={styles.login.form.submit}
            type="submit"
            disabled={loading}
          >
            {loading ? 'Signing Up...' : 'Sign Up'}
          </Button>

          {errors.submit && <p className="error">{errors.submit}</p>}
        </Grid>

        <Typography variant="body2" align="center">
          Already have an account?{' '}
          <Link
            onClick={onSignInClick}
            sx={styles.login.signup}
            disabled={loading}
          >
            Sign in
          </Link>
        </Typography>

        <div className="social-login-container">
          {/**To Do Add in Social Login **/}
        </div>
      </Box>
    </Grid>
  );
};

SignUpForm.propTypes = {
  onSuccess: PropTypes.func,
  onSignInClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  redirectPath: PropTypes.string,
};

export default SignUpForm;
