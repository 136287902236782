import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Box, Typography, Alert, Paper } from '@mui/material';
import { API_REQUEST } from '../helpers/request';
import {
  SupportDialog,
  ErrorMessage,
  UnprocessableMessage,
  ProgressBar,
} from '../components/processing';
import SignUpForm from '../components/account/sign-up-form';
import SignInPage from '../routes/sign-in';
import { useAuth } from '../context/AuthProvider';

// Configuration constant for total expected time in seconds
const TOTAL_PROGRESS_TIME = 180; // Adjust this value to set the total expected time
const FIRST_PHASE_PERCENTAGE = 90; // First phase reaches this percentage
const SECOND_PHASE_PERCENTAGE = 99; // Second phase reaches this percentage (final 1% reserved for completion)

// Custom hook for managing tender analysis status
const useTenderAnalysis = (projectId, packageId, signedUp) => {
  const [status, setStatus] = useState('PENDING');
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);
  const [startTime, setStartTime] = useState(null);

  const calculateProgress = useCallback(
    (currentStatus, elapsedSeconds) => {
      if (currentStatus === 'PENDING') return 10;
      if (currentStatus === 'STARTED') {
        // First half of the total time for first phase (10% to FIRST_PHASE_PERCENTAGE)
        const firstPhaseTime = TOTAL_PROGRESS_TIME / 2;
        if (elapsedSeconds <= firstPhaseTime) {
          return Math.min(
            FIRST_PHASE_PERCENTAGE,
            10 +
              ((FIRST_PHASE_PERCENTAGE - 10) * elapsedSeconds) / firstPhaseTime
          );
        } else {
          // Second half of the total time for second phase (FIRST_PHASE_PERCENTAGE to SECOND_PHASE_PERCENTAGE)
          const secondPhaseElapsed = Math.min(
            elapsedSeconds - firstPhaseTime,
            firstPhaseTime
          );
          return Math.min(
            SECOND_PHASE_PERCENTAGE,
            FIRST_PHASE_PERCENTAGE +
              ((SECOND_PHASE_PERCENTAGE - FIRST_PHASE_PERCENTAGE) *
                secondPhaseElapsed) /
                firstPhaseTime
          );
        }
      }
      if (['SUCCESS', 'FAILURE', 'UNPROCESSABLE'].includes(currentStatus))
        return 100;
      return progress;
    },
    [progress]
  );

  useEffect(() => {
    if (!signedUp) return;

    if (status === 'STARTED' && !startTime) {
      setStartTime(Date.now());
    }

    const progressInterval =
      status === 'STARTED'
        ? setInterval(() => {
            const elapsedSeconds = (Date.now() - startTime) / 1000;
            setProgress(calculateProgress(status, elapsedSeconds));
          }, 100)
        : null;

    return () => progressInterval && clearInterval(progressInterval);
  }, [status, startTime, calculateProgress, signedUp]);

  useEffect(() => {
    if (!signedUp) return;

    const checkStatus = async () => {
      try {
        const response = await API_REQUEST.get(
          `/projects/${projectId}/packages/${packageId}/tender_analysis`
        );
        const newStatus = response.data.status;
        if (newStatus !== status) {
          setStatus(newStatus);
          if (newStatus === 'PENDING') setProgress(10);
          if (newStatus === 'STARTED') {
            setStartTime(Date.now());
            setProgress(10);
          }
          if (newStatus === 'FAILURE') {
            setProgress(100);
            setError('The analysis process encountered an error.');
          }
          if (newStatus === 'UNPROCESSABLE') setProgress(100);
          if (newStatus === 'SUCCESS') setProgress(100);
        }
      } catch (err) {
        setError('Failed to check analysis status.');
        console.error('Status check failed:', err);
      }
    };

    checkStatus();
    const statusInterval = setInterval(() => {
      if (!['SUCCESS', 'FAILURE', 'UNPROCESSABLE'].includes(status)) {
        checkStatus();
      }
    }, 5000);

    return () => clearInterval(statusInterval);
  }, [projectId, packageId, status, signedUp]);

  if (!signedUp) {
    return { status: 'PENDING', error: null, progress: 0 };
  }

  return { status, error, progress };
};

const ProcessingPage = () => {
  const [isSignIn, setIsSignIn] = useState(false);
  const [signedUp, setSignedUp] = useState(false);
  const { projectId, packageId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { loggedIn, loading, setLoggedIn } = useAuth();
  useEffect(() => {
    if (loggedIn && !signedUp) {
      setSignedUp(true);
    }
  }, [loggedIn, signedUp]);

  const { status, error, progress } = useTenderAnalysis(
    projectId,
    packageId,
    signedUp
  );

  const [supportDialogOpen, setSupportDialogOpen] = useState(false);

  useEffect(() => {
    if (status === 'SUCCESS') {
      navigate(`/dashboard/project/${projectId}/package/${packageId}/result`);
    }
  }, [status, projectId, packageId, navigate]);

  const handleContactSupport = () => setSupportDialogOpen(true);
  const handleSignUpSuccess = async () => {
    setSignedUp(true);
    setLoggedIn(true);
    // Stay on the same page
    window.location.reload(); // This will trigger a fresh load with the new auth state
  };

  const handleViewDashboard = () => {
    navigate('/dashboard');
  };

  if (isSignIn) {
    return <SignInPage redirect={location.pathname} />;
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        bgcolor: '#475569',
      }}
    >
      {!signedUp && !loading && (
        <div id="sign-up-modal" className="modal-backdrop">
          <SignUpForm
            onSuccess={handleSignUpSuccess}
            subtitle="Your analysis has been queued. Sign up now to get your results."
            redirectPath={`/dashboard/project/${projectId}/package/${packageId}/processing`}
            onSignInClick={() => setIsSignIn(true)}
          />
        </div>
      )}
      {signedUp && (
        <Box sx={{ width: '400px', position: 'relative' }}>
          <Paper
            elevation={3}
            sx={{
              padding: 3,
              borderRadius: 2,
              marginBottom: 2,
            }}
          >
            <ProgressBar progress={progress} status={status} />

            <Alert
              severity="info"
              sx={{
                mt: 2,
                mb: 2,
                '& .MuiAlert-message': {
                  width: '100%',
                },
              }}
            >
              <Typography variant="body2" sx={{ mb: 1 }}>
                <strong>
                  The analysis might take a few minutes to complete.
                </strong>
              </Typography>
              <Typography variant="body2" sx={{ mb: 1 }}>
                It&#39;s safe to leave this page. You can check the results on
                the dashboard where the status will be shown.
              </Typography>
              <Typography variant="body2">
                Once ready, the status will be indicated in green.
              </Typography>
            </Alert>

            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <button
                onClick={handleViewDashboard}
                style={{
                  backgroundColor: '#4B5563',
                  color: 'white',
                  padding: '8px 16px',
                  borderRadius: '4px',
                  border: 'none',
                  cursor: 'pointer',
                  fontWeight: 'medium',
                }}
              >
                View Dashboard
              </button>
            </Box>

            {error && (
              <ErrorMessage
                error={error}
                onContactSupport={handleContactSupport}
              />
            )}
            {status === 'UNPROCESSABLE' && (
              <UnprocessableMessage onContactSupport={handleContactSupport} />
            )}
          </Paper>
        </Box>
      )}
      <SupportDialog
        open={supportDialogOpen}
        onClose={() => setSupportDialogOpen(false)}
      />
    </Box>
  );
};

export default ProcessingPage;
