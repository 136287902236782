import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { UploadFile as UploadFileIcon } from '@mui/icons-material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CloseIcon from '@mui/icons-material/Close';
import TableChartIcon from '@mui/icons-material/TableChart';
import WarningIcon from '@mui/icons-material/Warning';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const maxFiles = 3;
const maxFileSize = 10 * 1024 * 1024; // 10MB

const allowedFileTypes = {
  'application/pdf': ['.pdf'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
  ],
  'application/vnd.ms-excel': ['.xls'],
  'text/csv': ['.csv'],
};

const getFileTypeLabel = (mimeType) => {
  switch (mimeType) {
    case 'application/pdf':
      return 'PDF';
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/vnd.ms-excel':
      return 'Excel';
    case 'text/csv':
      return 'CSV';
    default:
      return 'Unknown';
  }
};

const FileUploader = ({ onFilesChange }) => {
  const [files, setFiles] = useState([]);
  const [overflowStates, setOverflowStates] = useState({});
  const [duplicateNameError, setDuplicateNameError] = useState('');
  const isMaxFiles = files.length >= maxFiles;
  const [rejectedFiles, setRejectedFiles] = useState([]);

  const isFileTypeAllowed = (file) => {
    return Object.keys(allowedFileTypes).some((type) => {
      return file.type === type;
    });
  };

  const hasDuplicateFileName = (newFileName, existingFiles) => {
    return existingFiles.some(
      (existingFile) =>
        existingFile.file.path.toLowerCase() === newFileName.toLowerCase()
    );
  };
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (files.length >= maxFiles) {
        return;
      }

      const duplicateFiles = acceptedFiles.filter((file) =>
        hasDuplicateFileName(file.name, files)
      );

      if (duplicateFiles.length > 0) {
        setRejectedFiles([
          {
            name: duplicateFiles[0].name,
            errors: [{ message: 'A file with this name already exists' }],
          },
        ]);
        setTimeout(() => setRejectedFiles([]), 3000);
        return;
      }

      const validFiles = acceptedFiles
        .map((file) => {
          const errors = [];

          if (!isFileTypeAllowed(file)) {
            errors.push('Invalid file type. Only PDF files are allowed.');
          }

          if (file.size > maxFileSize) {
            errors.push('File too large. Maximum size is 10MB.');
          }

          return {
            id: crypto.randomUUID(),
            file: {
              path: file.name,
              preview: URL.createObjectURL(file),
              type: file.type,
            },
            rawFile: file,
            errors,
            subcontractor: '',
          };
        })
        .slice(0, maxFiles - files.length);

      setFiles((prevFiles) => {
        const updatedFiles = [...prevFiles, ...validFiles];
        onFilesChange(updatedFiles);

        validFiles.forEach((file) => {
          if (file.errors.length > 0) {
            URL.revokeObjectURL(file.file.preview);
          }
        });

        return updatedFiles;
      });
    },
    [files, onFilesChange]
  );

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop,
      onDropRejected: (rejectedFiles) => {
        setRejectedFiles(
          rejectedFiles.map((rf) => ({
            name: rf.file.name,
            errors: rf.errors,
          }))
        );
        setTimeout(() => setRejectedFiles([]), 3000);
      },
      accept: allowedFileTypes,
      multiple: true,
      maxFiles: maxFiles,
      disabled: isMaxFiles,
      noClick: isMaxFiles,
      noDrag: isMaxFiles,
    });

  function checkForDuplicateSubcontractors(filesArray) {
    if (filesArray.every((file) => file.subcontractor === '')) {
      setDuplicateNameError('');
      return;
    }

    const duplicatedNames =
      new Set(filesArray.map((file) => file.subcontractor.toLowerCase())).size <
      filesArray.length;

    setDuplicateNameError(
      duplicatedNames ? 'Duplicate subcontractor names are not allowed' : ''
    );
  }

  const handleRemoveFile = (id) => {
    setFiles((prevFiles) => {
      const updatedFiles = prevFiles.filter((file) => file.id !== id);
      onFilesChange(updatedFiles);
      checkForDuplicateSubcontractors(updatedFiles);
      return updatedFiles;
    });

    setOverflowStates((prev) => {
      const updated = { ...prev };
      delete updated[id];
      return updated;
    });
  };

  const handleSubcontractorChange = (id, value) => {
    const hasOverflow = value.length > 25;
    setOverflowStates((prev) => ({ ...prev, [id]: hasOverflow }));

    setFiles((prevFiles) => {
      const updatedFiles = prevFiles.map((file) =>
        file.id === id ? { ...file, subcontractor: value } : file
      );
      onFilesChange(updatedFiles);
      checkForDuplicateSubcontractors(updatedFiles);
      return updatedFiles;
    });
  };

  const getFileIcon = (fileType) => {
    if (fileType === 'application/pdf') {
      return <PictureAsPdfIcon />;
    } else if (
      fileType ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      fileType === 'application/vnd.ms-excel' ||
      fileType === 'text/csv'
    ) {
      return <TableChartIcon />;
    }
    return <InsertDriveFileIcon />;
  };

  return (
    <div className="uploader">
      {rejectedFiles.length > 0 && (
        <div className="invalid-file-alert">
          <ErrorOutlineIcon />
          <div className="invalid-file-content">
            <p className="invalid-filename">
              Cannot upload: {rejectedFiles[0].name}
            </p>
            <p>Only PDF files are allowed (max 10MB)</p>
          </div>
        </div>
      )}
      {duplicateNameError && (
        <div className="invalid-file-alert">
          <ErrorOutlineIcon />
          <div className="invalid-file-content">
            <p>{duplicateNameError}</p>
          </div>
        </div>
      )}
      <div
        {...getRootProps()}
        className={`dropzone ${isDragActive ? 'active' : ''}
                   ${isDragReject ? 'file-rejected' : ''}
                   ${isMaxFiles ? 'max-files disabled' : ''}`}
      >
        <UploadFileIcon />
        <input {...getInputProps()} disabled={isMaxFiles} />{' '}
        {isDragActive ? (
          <p>Drop the files here...</p>
        ) : (
          <p>
            {isMaxFiles
              ? 'Upload limit reached'
              : 'Click to upload files or drag and drop'}
          </p>
        )}
        <p>
          Accepts PDF, Excel (.xlsx, .xls), and CSV files (max {maxFiles} files,
          10MB each)
        </p>
      </div>

      <div id="uploader-file-container">
        <p className="quote-upload-title">Uploaded Files</p>
        <p className="file-count">
          {files.length} of {maxFiles} files uploaded.
          {isMaxFiles && (
            <span className="max-files-text"> Maximum files reached</span>
          )}
        </p>

        {files.map((file) => (
          <div
            className={`file-preview-container ${file.errors.length > 0 ? 'file-error' : ''}`}
            key={file.id}
          >
            <div className="file-info">
              {getFileIcon(file.file.type)}
              <div className="filename-container">
                <span>{file.file.path}</span>
                <div className="filename-tooltip">{file.file.path}</div>
              </div>
              <span className="file-type-badge">
                {getFileTypeLabel(file.file.type)}
              </span>
            </div>
            {file.errors.length > 0 ? (
              <p className="error">
                <WarningIcon className="error-file-icon" />
                {file.errors.join(', ')}
              </p>
            ) : (
              <input
                className={`subcontractor-input ${overflowStates[file.id] ? 'overflow' : ''}`}
                type="text"
                placeholder="Subcontractor Name"
                value={file.subcontractor}
                onChange={(e) =>
                  handleSubcontractorChange(file.id, e.target.value)
                }
              />
            )}
            <CloseIcon
              onClick={() => handleRemoveFile(file.id)}
              className="remove-file-icon"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

FileUploader.propTypes = {
  onFilesChange: PropTypes.func.isRequired,
};

export default FileUploader;
