const styles = {
  page: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#2b3946',
    color: '#fff',
    position: 'relative',
  },
  limitWidth: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '0 20px',
  },
  navigation: {
    position: 'relative',
    padding: '12px 0',
    width: '100%',
    logoContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
    logo: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      fontFamily: 'RooneySans',
      text: {
        fontSize: '28px',
        fontWeight: 'bold',
        color: '#f4b82e',
        marginRight: '4px',
      },
      poweredBy: {
        fontSize: '14px',
        color: '#ffffff',
        marginLeft: '4px',
        fontWeight: 'normal',
        display: 'inline-flex',
        alignItems: 'center',
      },
      alphaBannerContainer: {
        display: 'flex',
        justifyContent: 'center',

        transform: 'translate(0, -14px)',
      },
      alphaBanner: {
        backgroundColor: '#4CC0AD',
        color: '#fff',
        fontSize: '12px',
        fontWeight: 600,
        padding: '2px 8px',
        borderRadius: '4px',
        letterSpacing: '1px',
      },
      roundLogo: {
        width: '30px',
        marginRight: '8px',
      },
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      position: 'absolute',
      right: '20px',
      top: '14px',
    },
  },
  navContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  header: {
    textAlign: 'center',
    color: '#fff',
    background: 'linear-gradient(180deg, #2b3946, #1A2A38)',
    padding: { xs: '40px 20px', md: '80px 0 50px' },
    fontFamily: 'RooneySans',
    h1: {
      fontWeight: 800,
      fontSize: { xs: '42px', md: '64px' },
      lineHeight: 1,
      letterSpacing: '-2.04px',
      margin: '0 auto',
      maxWidth: '1000px',
      fontFamily: 'RooneySans',
      span: {
        color: '#f4b82e',
        fontSize: { xs: '42px', md: '64px' },
        fontWeight: 800,
        lineHeight: 1,
        fontFamily: 'RooneySans',
      },
    },
    h2: {
      fontWeight: 400,
      fontSize: { xs: '22px', md: '28px' },
      letterSpacing: '-0.84px',
      margin: '30px 0',
      fontFamily: 'RooneySans',
      '&.limited-width': {
        maxWidth: '700px',
        margin: '50px auto',
      },
      span: {
        color: '#f4b82e',
        fontSize: { xs: '22px', md: '28px' },
        fontWeight: 400,
        lineHeight: 1,
        fontFamily: 'RooneySans',
      },
    },
    '&.download-section': {
      padding: { xs: '60px 0', md: '150px 0' },
    },
  },
  sectionHeader: {
    textAlign: 'left',
    margin: '20px 0',
    color: '#f4b82e',
    fontWeight: 600,
    fontSize: '24px',
    fontFamily: 'RooneySans',
  },
  button: {
    margin: '8px',
    borderRadius: '20px',
    textTransform: 'none',
    padding: '8px 24px',
    height: '40px',
    fontFamily: 'RooneySans',
    '&.page-button': {
      width: { xs: 'calc(100% - 40px)', md: 'auto' },
      fontSize: '17px',
    },
    login: {
      fontFamily: 'RooneySans',
      backgroundColor: '#f4b82e',
      color: '#000',
    },
    signup: {
      fontFamily: 'RooneySans',
      backgroundColor: '#4CC0AD',
    },
    largeSignup: {
      padding: '12px 36px',
      height: '48px',
      fontSize: '18px',
      fontWeight: '600',
    },
    topLogin: {
      padding: '6px 20px',
      height: '36px',
      minWidth: '80px',
      borderRadius: '20px',
      fontSize: '16px',
    },
    topSignup: {
      padding: '6px 20px',
      height: '36px',
      minWidth: '80px',
      borderRadius: '20px',
      fontSize: '16px',
      whiteSpace: 'nowrap',
    },
  },
  works: {
    fontFamily: 'RooneySans',
    padding: { xs: '0', sm: '30px 0' },
    title: {
      fontFamily: 'RooneySans',
      fontWeight: 600,
      color: '#f4b82e',
      textAlign: 'center',
      fontSize: '32px',
      margin: '16px 0 24px',
    },
  },
  unlock: {
    fontFamily: 'RooneySans',
    paddingBottom: '12px',
    title: {
      fontFamily: 'RooneySans',
      fontSize: { xs: '32px', md: '48px' },
      fontWeight: 600,
      marginBottom: '16px',
      textAlign: 'center',
      maxWidth: { xs: '270px', md: '800px' },
      margin: '30px auto',
      span: {
        fontFamily: 'RooneySans',
        fontSize: { xs: '32px', md: '48px' },
        fontWeight: 600,
        color: '#f4b82e',
      },
    },
    textSection: {
      fontFamily: 'RooneySans',
      margin: '20px 0',
      title: {
        fontFamily: 'RooneySans',
        fontSize: { xs: '36px', md: '40px' },
        fontWeight: 600,
        color: '#f4b82e',
        maxWidth: '500px',
        '&.orange': {
          color: '#EE7219',
        },
        '&.blue': {
          color: '#4CC0AD',
        },
      },
      subtitle: {
        fontFamily: 'RooneySans',
        fontSize: { xs: '24px', md: '32px' },
        marginBottom: { xs: '80px', md: 0 },
        fontWeight: 500,
        color: '#fff',
        maxWidth: '500px',
      },
    },
    image: {
      width: 'calc(100% - 30px)',
      height: 'auto',
      margin: { xs: '30px auto 0', md: 'auto' },
    },
    rowReverse: {
      flexDirection: { xs: 'column-reverse!important', md: 'row!important' },
    },
  },
  icon: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    width: '30%',
    marginBottom: '30px',
    img: {
      marginBottom: '1rem',
      '&.icon-1': {
        height: '83px',
        width: '83px',
      },
      '&.icon-2': {
        height: '83px',
        width: '121px',
      },
      '&.icon-3': {
        height: '83px',
        width: '156px',
      },
    },
    title: {
      fontFamily: 'RooneySans',
      fontSize: '20px',
      fontWeight: 600,
      color: '#f4b82e',
      margin: '8px 0',
    },
    description: {
      fontFamily: 'RooneySans',
      fontSize: '16px',
      lineHeight: '2',
      fontWeight: 500,
      maxWidth: '80%',
    },
  },
  choose: {
    fontFamily: 'RooneySans',
    padding: '70px 0',
    backgroundColor: '#08202a',
    title: {
      fontFamily: 'RooneySans',
      fontSize: '40px',
      fontWeight: 600,
      color: '#f4b82e',
      marginBottom: '16px',
      textAlign: 'center', // Centered
      span: {
        fontFamily: 'RooneySans',
        fontSize: '40px',
        fontWeight: 600,
        color: '#4CC0AD',
      },
    },
    list: {
      fontFamily: 'RooneySans',
      listStyleType: 'none',
      padding: 0,
      textAlign: 'left',
      color: '#fff',
      listItem: {
        marginBottom: '16px',
        fontSize: '24px',
      },
      checkmark: {
        color: '#f4b82e',
        marginRight: '8px',
      },
    },
  },
  references: {
    fontFamily: 'RooneySans',
    backgroundColor: '#f4b82e',
    padding: '40px 0 60px',
    item: {
      padding: '32px 0',
      display: { xs: 'flex', lg: 'unset' },
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      fontFamily: 'RooneySans',
      fontSize: '56px',
      fontWeight: 600,
      color: '#08202a',
      textAlign: 'left',
      span: {
        fontFamily: 'RooneySans',
        fontSize: '24px',
        fontWeight: 600,
      },
    },
    subtitle: {
      fontFamily: 'RooneySans',
      color: '#fff',
      fontSize: '24px',
      fontWeight: 600,
      maxWidth: '300px',
    },
  },
  faq: {
    padding: '60px 0',
    heading: {
      fontFamily: 'RooneySans',
      color: '#fff',
      fontWeight: 600,
      fontSize: '22px',
    },
    title: {
      fontFamily: 'RooneySans',
      color: '#f4b82e',
      fontSize: '20px',
      margin: '30px 0 20px',
      fontWeight: 600,
    },
    subtitle: {
      fontFamily: 'RooneySans',
      color: '#fff',
    },
  },
  imageSection: {
    backgroundColor: '#08202a',
    padding: '40px 0',
  },
  helpSupport: {
    back: {},
    container: {
      padding: '100px 20px 80px',
      minHeight: 'calc(100vh - 70px)',
    },
    heading: {
      fontFamily: 'RooneySans',
      fontSize: '40px',
      fontWeight: 600,
      color: '#f4b82e',
      marginBottom: '16px',
    },
    title: {
      fontFamily: 'RooneySans',
      fontSize: '32px',
      fontWeight: 600,
      color: '#fff',
      marginBottom: '16px',
    },
    subtitle: {
      fontFamily: 'RooneySans',
      fontSize: '20px',
      fontWeight: 600,
      color: '#f4b82e',
      marginBottom: '16px',
    },
    paragraph: {
      fontFamily: 'RooneySans',
      fontSize: '16px',
      color: '#fff',
      marginBottom: '16px',
    },
    contact: {
      fontFamily: 'RooneySans',
      color: '#f4b82e',
    },
  },
  // Adding sidebar styles
  sidebar: {
    main: {
      backgroundColor: '#2b3946',
      color: '#fff',
    },
    header: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      padding: '16px',
      position: 'relative',
    },
    logoContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    logoText: {
      fontFamily: 'RooneySans',
      fontSize: '28px',
      fontWeight: 'bold',
    },
    qsText: {
      color: '#f4b82e',
    },
    aiText: {
      color: '#4CC0AD',
    },
    poweredByContainer: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '4px',
    },
    poweredByText: {
      fontSize: '14px',
      color: '#ffffff',
      fontWeight: 'normal',
      marginRight: '8px',
    },
    alphaBadge: {
      backgroundColor: '#4CC0AD',
      color: '#fff',
      fontSize: '12px',
      fontWeight: 600,
      padding: '2px 8px',
      borderRadius: '4px',
      letterSpacing: '1px',
    },
    roundLogo: {
      width: '30px',
      marginRight: '8px',
    },
    nav: {
      // Add any nav-specific styles if needed
    },
    navItem: {
      // Add any styles for nav items if needed
      '&.active': {
        // Style for active item
      },
    },
    navIcon: {
      // Style for nav icons
    },
    feedbackButton: {
      // Style for feedback button if needed
    },
  },
};

export default styles;
