import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom/client';
import './sass/index.scss';
import {
  RouterProvider,
  createBrowserRouter,
  Navigate,
} from 'react-router-dom';
import { useAuth } from './context/AuthProvider';
// Import the layouts
import RootLayout from './layouts/root-layout';
import PublicLayout from './layouts/public-layout';
import DashboardLayout from './layouts/dashboard-layout';
// Import the components
import SignInPage from './routes/sign-in';
import SignUpPage from './routes/sign-up';
import IntroPage from './routes/intro';
import HelpSupportPage from './routes/help-support';
import TermsAndConditions from './routes/terms-conditions';
import PasswordResetPage from './routes/reset-password';
import Test from './routes/test';
import DashboardPage from './routes/dashboard';
import AnalysisResults from './routes/dashboard/result';
import ProcessingPage from './routes/processing';
import NotFoundPage from './routes/error/notFound';
import { FEATURES } from './constants';
import AccountActivationPage from './routes/account-activation';

const RootRedirect = () => {
  const { loggedIn, loading } = useAuth();
  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <Navigate
      to={
        loggedIn ? '/dashboard' : FEATURES.ENABLE_SIGNUP ? '/intro' : '/sign-in'
      }
      replace
    />
  );
};

const ProtectedRoute = ({ children }) => {
  const { loggedIn, loading } = useAuth();

  if (loading && loggedIn) {
    return <div>Loading...</div>; // Or your loading component
  }

  if (!loading && !loggedIn) {
    return <Navigate to="/intro" replace />;
  }

  return children;
};
ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

const router = createBrowserRouter([
  {
    element: <RootLayout />,
    path: '/',
    children: [
      // Catch-all route for 404 across the app
      { path: '*', element: <NotFoundPage /> },
      {
        element: (
          <ProtectedRoute>
            <DashboardLayout />
          </ProtectedRoute>
        ),
        path: '/dashboard',
        children: [
          { path: '', element: <DashboardPage /> },
          {
            path: 'project/:id/package/:packageId/result',
            element: <AnalysisResults />,
          },
          { pah: '*', element: <Navigate to="/404" replace /> },
        ],
      },
      {
        element: <PublicLayout />,
        path: '/',
        children: [
          {
            path: '/',
            element: <RootRedirect />,
          },
          { path: '/sign-in/*', element: <SignInPage /> },
          {
            path: '/password-reset/:token',
            element: <PasswordResetPage isActivation={false} />,
          },
          {
            path: '/password-reset/',
            element: <PasswordResetPage isActivation={false} />,
          },
          {
            path: '/activate-account/:token',
            element: <PasswordResetPage isActivation={true} />,
          },
          { path: '/activate/:token', element: <AccountActivationPage /> },
          { path: '/help-support', element: <HelpSupportPage /> },
          { path: '/terms', element: <TermsAndConditions /> },
          { path: '/sign-up/*', element: <SignUpPage /> },
        ],
      },
      {
        element: <PublicLayout />,
        path: '/intro/',
        children: [
          {
            path: '',
            element: FEATURES.ENABLE_SIGNUP ? (
              <IntroPage />
            ) : (
              <Navigate to="/sign-in" replace />
            ),
          },
        ],
      },
      {
        element: <PublicLayout />,
        path: '/processing/:projectId/:packageId',
        children: [
          {
            path: '',
            element: <ProcessingPage />,
          },
        ],
      },
      {
        element: <PublicLayout />,
        path: '/test/',
        children: [
          {
            path: '',
            element: <Test />,
            children: [{ path: '', element: <Test /> }],
          },
        ],
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
