import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { SECTION_CONFIG } from './sectionConfig';

const formatHeader = (key) => {
  return key
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const isPrimitiveArray = (arr) => {
  if (!Array.isArray(arr) || arr.length === 0) return false;
  return arr.every(
    (item) =>
      typeof item === 'string' ||
      typeof item === 'number' ||
      typeof item === 'boolean'
  );
};

const isObjectArray = (arr) => {
  if (!Array.isArray(arr) || arr.length === 0) return false;
  return arr.every(
    (item) => typeof item === 'object' && item !== null && !Array.isArray(item)
  );
};

const renderConfigurableTable = (data, sectionKey, subsectionKey) => {
  // Get column configuration if available
  const config = SECTION_CONFIG.subsectionConfig[sectionKey];
  let orderedColumns = [];

  if (config && config.columns && config.columns[subsectionKey]) {
    // Use configured columns
    orderedColumns = config.columns[subsectionKey];
  } else {
    // Fallback: Get all unique keys from the array items
    orderedColumns = [...new Set(data.flatMap((item) => Object.keys(item)))];
  }

  // Use column renderers from config if available
  const columnRenderers = SECTION_CONFIG.columnRenderers || {};

  return (
    <Table>
      <TableHead>
        <TableRow>
          {orderedColumns.map((key) => (
            <TableCell key={key} sx={{ fontWeight: 'bold' }}>
              {formatHeader(key)}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item, rowIndex) => (
          <TableRow key={rowIndex}>
            {orderedColumns.map((key) => (
              <TableCell key={`${rowIndex}-${key}`}>
                {item[key] === undefined
                  ? ''
                  : Array.isArray(item[key])
                    ? columnRenderers[key]
                      ? columnRenderers[key](item[key])
                      : renderNestedValue(item[key], key)
                    : typeof item[key] === 'object' && item[key] !== null
                      ? JSON.stringify(item[key])
                      : String(item[key])}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

// Main render function with section/subsection awareness
const renderSection = (sectionData, sectionKey, subsectionKey) => {
  // Handle arrays
  if (Array.isArray(sectionData)) {
    if (sectionData.length === 0) return null;

    // Case 1: Array of primitives (strings, numbers, booleans)
    if (isPrimitiveArray(sectionData)) {
      return (
        <List dense>
          {sectionData.map((item, index) => (
            <ListItem key={index}>
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
      );
    }

    // Case 2: Array of objects - use configurable table if we have section/subsection info
    if (isObjectArray(sectionData)) {
      if (sectionKey && subsectionKey) {
        return renderConfigurableTable(sectionData, sectionKey, subsectionKey);
      }

      // Fallback to original behavior
      const allKeys = [
        ...new Set(sectionData.flatMap((item) => Object.keys(item))),
      ];

      return (
        <Table>
          <TableHead>
            <TableRow>
              {allKeys.map((key) => (
                <TableCell key={key} sx={{ fontWeight: 'bold' }}>
                  {formatHeader(key)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sectionData.map((item, rowIndex) => (
              <TableRow key={rowIndex}>
                {allKeys.map((key) => (
                  <TableCell key={`${rowIndex}-${key}`}>
                    {item[key] === undefined
                      ? ''
                      : Array.isArray(item[key])
                        ? renderNestedValue(item[key])
                        : typeof item[key] === 'object' && item[key] !== null
                          ? JSON.stringify(item[key])
                          : String(item[key])}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    }

    // Case 3: Mixed array - render each item based on its type
    return (
      <Box>
        {sectionData.map((item, index) => (
          <Box key={index} sx={{ mb: 2 }}>
            {renderSection(item, sectionKey, `${subsectionKey}_${index}`)}
          </Box>
        ))}
      </Box>
    );
  }

  // Handle objects
  else if (typeof sectionData === 'object' && sectionData !== null) {
    // Check if we have a subsection order configuration
    const config = sectionKey && SECTION_CONFIG.subsectionConfig[sectionKey];
    let subsections = Object.entries(sectionData);

    // If we have a configuration for this section, order the subsections
    if (config && config.subsectionOrder) {
      const orderMap = config.subsectionOrder.reduce(
        (acc, key, idx) => ({ ...acc, [key]: idx }),
        {}
      );

      subsections.sort(([keyA], [keyB]) => {
        const orderA = orderMap[keyA] ?? Number.MAX_SAFE_INTEGER;
        const orderB = orderMap[keyB] ?? Number.MAX_SAFE_INTEGER;
        return orderA - orderB;
      });
    }

    return subsections.map(([key, value]) => (
      <Box key={key} sx={{ mb: 3 }}>
        <Typography variant="h6" className="section-subheading">
          {formatHeader(key)}
        </Typography>
        {renderSection(value, sectionKey, key)}
      </Box>
    ));
  }

  // Handle primitive values
  else if (sectionData !== undefined && sectionData !== null) {
    return (
      <Typography variant="body1" sx={{ px: 2 }}>
        {String(sectionData)}
      </Typography>
    );
  }

  return null;
};

// Helper function to render nested array values in table cells
const renderNestedValue = (value, columnKey) => {
  if (Array.isArray(value)) {
    // If array is empty, return configured placeholder text
    if (value.length === 0) {
      // Get the placeholder text from config, fallback to default
      const emptyText =
        SECTION_CONFIG.emptyArrayText[columnKey] ||
        SECTION_CONFIG.emptyArrayText.default ||
        'None';
      return emptyText;
    }

    // Regular case for non-empty arrays
    if (isPrimitiveArray(value)) {
      return value.join(', ');
    }
  }
  return JSON.stringify(value);
};

export default renderSection;
