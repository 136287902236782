import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  AlertCircle,
  ChevronUp,
  ChevronDown,
  FileText,
  Download,
  Building,
  Loader2,
} from 'lucide-react';
import { Alert } from 'react-bootstrap';
import { API_REQUEST } from '../../helpers/request';
import { API } from '../../constants';
import PropTypes from 'prop-types';
import ListContent from './ListContent';
import { SECTION_CONFIG } from './sectionConfig';

const StatusDisplay = ({ status, message }) => {
  const statusConfig = {
    PENDING: {
      icon: <Loader2 className="h-5 w-5" />,
      title: 'Analysis Queued',
      description:
        'Your tender analysis is queued for processing. Please check back later.',
      variant: 'info',
    },
    STARTED: {
      icon: <Loader2 className="h-6 w-6" />,
      title: 'Analysis in Progress',
      description:
        'Your tender analysis is being processed. Please check back later.',
      variant: 'info',
    },
    FAILURE: {
      icon: <AlertCircle className="h-5 w-5" />,
      title: 'Analysis Failed',
      description:
        message ||
        'There was an error processing your tender analysis. Please try again.',
      variant: 'danger',
    },
    UNPROCESSABLE: {
      icon: <AlertCircle className="h-5 w-5" />,
      title: 'Analysis Incomplete',
      description:
        message ||
        'The analysis completed but some data could not be processed. Please review the partial results below.',
      variant: 'warning',
    },
  };

  const config = statusConfig[status];
  if (!config) return null;

  return (
    <Alert variant={config.variant} className="mb-4">
      <div className="d-flex align-items-center gap-2">
        {config.icon}
        <div>
          <h6 className="mb-1">{config.title}</h6>
          <p className="mb-0">{config.description}</p>
        </div>
      </div>
    </Alert>
  );
};
StatusDisplay.propTypes = {
  status: PropTypes.string.isRequired,
  message: PropTypes.string,
};
const LoadingSpinner = () => (
  <div className="analysis-container">
    <div className="loading">
      <div className="spinner" />
    </div>
  </div>
);

const ErrorDisplay = ({ message }) => (
  <div className="analysis-container">
    <div className="error">
      <AlertCircle className="w-5 h-5" />
      {message}
    </div>
  </div>
);

ErrorDisplay.propTypes = {
  message: PropTypes.string.isRequired,
};

const SubcontractorCard = ({ name, files }) => (
  <div className="subcontractor-card">
    <div className="subcontractor-header">
      <Building className="w-4 h-4" />
      <h3 className="subcontractor-name">{name}</h3>
    </div>
    <div className="files-list">
      {files.map((file, index) => (
        <div key={index} className="file-item">
          <FileText className="w-4 h-4" />
          <span className="file-name" title={file}>
            {file}
          </span>
        </div>
      ))}
    </div>
  </div>
);

SubcontractorCard.propTypes = {
  name: PropTypes.string.isRequired,
  files: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const AnalysisResults = () => {
  const { id: projectId, packageId } = useParams();
  const [state, setState] = useState({
    analysisData: null,
    projectData: null,
    packageData: null,
    subcontractors: [],
    loading: true,
    error: null,
  });

  // Initialize expanded sections with config order
  const [expandedSections, setExpandedSections] = useState(
    SECTION_CONFIG.sectionOrder.reduce(
      (acc, section) => ({ ...acc, [section]: section === 'meta' }),
      {}
    )
  );

  useEffect(() => {
    const fetchData = async () => {
      if (!projectId || !packageId) {
        setState((prev) => ({
          ...prev,
          error: 'Missing project ID or package ID',
          loading: false,
        }));
        return;
      }

      try {
        const [projectRes, packageRes, analysisRes, quotesRes] =
          await Promise.all([
            API_REQUEST.get(`projects/${projectId}`),
            API_REQUEST.get(`projects/${projectId}/packages/${packageId}`),
            API_REQUEST.get(
              `projects/${projectId}/packages/${packageId}/tender_analysis`
            ),
            API_REQUEST.get(
              `projects/${projectId}/packages/${packageId}/quotes`
            ),
          ]);

        const subcontractors = API_REQUEST.isSuccess(quotesRes.status)
          ? quotesRes.data.reduce((acc, quote) => {
              const existingIndex = acc.findIndex(
                (item) => item.name === quote.subcontractor_name
              );
              if (existingIndex >= 0) {
                acc[existingIndex].files.push(quote.file_name);
              } else {
                acc.push({
                  name: quote.subcontractor_name,
                  files: [quote.file_name],
                });
              }
              return acc;
            }, [])
          : [];

        setState({
          projectData: projectRes.data,
          packageData: packageRes.data,
          analysisData: analysisRes.data,
          subcontractors,
          loading: false,
          error: null,
        });
      } catch (err) {
        setState((prev) => ({
          ...prev,
          error: 'Failed to load data',
          loading: false,
        }));
      }
    };

    fetchData();
  }, [projectId, packageId]);

  const handleExportAnalysis = () => {
    window.location.href = API.url(
      `/projects/${projectId}/packages/${packageId}/tender_analysis/export/docx`
    );
  };

  if (state.loading) return <LoadingSpinner />;
  if (state.error) return <ErrorDisplay message={state.error} />;

  // eslint-disable-next-line no-unused-vars
  const { projectData, packageData, analysisData, subcontractors } = state;
  const showResults = analysisData?.status === 'SUCCESS';

  return (
    <div className="analysis-wrapper">
      <div className="analysis-container">
        <div className="header">
          <h1 className="title">{projectData?.name || 'Project Details'}</h1>
          <div className="project-info">
            <div className="metadata">
              <div className="info-item">
                <strong>Package name:</strong>{' '}
                {packageData?.name || 'Loading...'}
              </div>
              <div className="info-item">
                <strong>Created:</strong>{' '}
                {packageData?.created
                  ? new Date(packageData.created).toLocaleDateString('en-GB')
                  : 'Loading...'}
              </div>
            </div>
          </div>
        </div>

        {analysisData?.status !== 'SUCCESS' && (
          <StatusDisplay
            status={analysisData?.status}
            message={analysisData?.error_message}
          />
        )}

        {showResults && (
          <>
            <h2 className="project-analysis-title">Project Analysis</h2>

            {SECTION_CONFIG.sectionOrder.map((section) => {
              const data = analysisData?.[section];
              if (!data) return null;

              return (
                <div key={section} className="analysis-section">
                  <button
                    onClick={() =>
                      setExpandedSections((prev) => ({
                        ...prev,
                        [section]: !prev[section],
                      }))
                    }
                    className="section-header"
                  >
                    <div className="section-title">
                      <span className="title-text">
                        {SECTION_CONFIG.sectionTitles[section]}
                      </span>
                    </div>
                    <div
                      className={`chevron ${
                        expandedSections[section] ? 'expanded' : ''
                      }`}
                    >
                      {expandedSections[section] ? (
                        <ChevronUp />
                      ) : (
                        <ChevronDown />
                      )}
                    </div>
                  </button>

                  {expandedSections[section] && (
                    <div className="section-content">
                      <ListContent data={data} sectionKey={section} />
                    </div>
                  )}
                </div>
              );
            })}
          </>
        )}
      </div>

      <div className="side-panel">
        <div className="panel-content">
          <div className="panel-header">
            <h2 className="panel-title">Package Overview</h2>
            {showResults && (
              <button
                onClick={handleExportAnalysis}
                className="export-button"
                title="Export Analysis"
              >
                <Download className="w-4 h-4" />
                <span>Export</span>
              </button>
            )}
          </div>

          <div className="subcontractors-section">
            {subcontractors.map((subcontractor, index) => (
              <SubcontractorCard key={index} {...subcontractor} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalysisResults;
