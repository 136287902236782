import { useLocation, Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import {
  LayoutDashboard,
  HelpCircle,
  Info,
  MessageSquare,
  FileText,
} from 'lucide-react';
import { proqouImages, FEATURES } from '../../constants/index';
import styles from '../../components/muiStyles';

const Sidebar = () => {
  const location = useLocation();
  const isActive = (path) => location.pathname.startsWith(path);

  const menuItems = [
    { icon: LayoutDashboard, label: 'Dashboard', path: '/dashboard' },
    { icon: HelpCircle, label: 'FAQ`s', path: '/help-support' },
    { icon: FileText, label: 'Terms & Conditions', path: '/terms' },
  ];

  const handleFeedback = () => {
    window.open(
      `mailto:${FEATURES.CONTACT_EMAIL}?subject=QSAI%20Feedback%20-%20Alpha%20Version`,
      '_blank'
    );
  };

  return (
    <aside className="sidebar" style={styles.sidebar.main}>
      <div className="sidebar-header">
        <Box sx={styles.sidebar.header}>
          {/* Logo and QSAI Text - Horizontal Layout */}
          <Box sx={styles.sidebar.logoContainer}>
            <img
              src={`${proqouImages}/ai-round-logo.svg`}
              alt="QSAI"
              style={styles.sidebar.roundLogo}
            />
            <Typography sx={styles.sidebar.logoText}>
              <span style={styles.sidebar.qsText}>QS</span>
              <span style={styles.sidebar.aiText}>AI</span>
            </Typography>
          </Box>

          {/* Powered by text with Alpha badge */}
          <Box sx={styles.sidebar.poweredByContainer}>
            <Typography sx={styles.sidebar.poweredByText}>
              POWERED BY C-LINK
            </Typography>
            <Typography sx={styles.sidebar.alphaBadge}>ALPHA</Typography>
          </Box>
        </Box>
      </div>

      <nav className="sidebar-nav" style={styles.sidebar.nav}>
        {menuItems.map((item, index) => (
          <Link
            key={index}
            to={item.path}
            className={`nav-item ${isActive(item.path) ? 'active' : ''}`}
            style={styles.sidebar.navItem}
          >
            <item.icon className="nav-icon" />
            <span>{item.label}</span>
          </Link>
        ))}

        <button
          onClick={handleFeedback}
          className="nav-item feedback-button"
          style={styles.sidebar.feedbackButton}
        >
          <MessageSquare className="nav-icon" />
          <span>Send Feedback</span>
        </button>

        <a
          href="https://c-link.com"
          target="_blank"
          rel="noopener noreferrer"
          className="nav-item"
          style={styles.sidebar.navItem}
        >
          <Info className="nav-icon" />
          <span>About C-Link</span>
        </a>
      </nav>
    </aside>
  );
};

export default Sidebar;
