// sectionConfig.js
export const SECTION_CONFIG = {
  // Main sections order
  sectionOrder: [
    'executive_summary',
    'price_analysis',
    'programme_analysis',
    'inclusions_exclusions',
    'risks',
    'opportunities',
    'clarifications_required',
  ],

  // Section titles mapping
  sectionTitles: {
    executive_summary: 'Executive Summary',
    price_analysis: 'Price Analysis',
    programme_analysis: 'Programme Analysis',
    inclusions_exclusions: 'Quote Inclusions and Exclusions',
    risks: 'Risks',
    opportunities: 'Opportunities',
    clarifications_required: 'Clarifications Required',
  },

  // Subsections and their column configurations
  subsectionConfig: {
    // Executive Summary subsections
    executive_summary: {
      subsectionOrder: [
        'key_findings',
        'price_comparison',
        'programme_comparison',
        'quote_quality_assessment',
        'recommendations',
      ],
      columns: {
        price_comparison: ['subcontractor', 'total_price', 'vat_included'],
        programme_comparison: [
          'subcontractor',
          'lead_in_period',
          'total_weeks',
          'constraints',
        ],
        quote_quality_assessment: [
          'subcontractor',
          'quality',
          'strengths',
          'weaknesses',
        ],
      },
    },

    // Price Analysis subsections
    price_analysis: {
      subsectionOrder: [
        'total_prices',
        'preliminaries',
        'other_fees',
        'provisional_sums',
      ],
      columns: {
        total_prices: ['subcontractor', 'base_price', 'vat', 'total_inc_vat'],
        preliminaries: ['subcontractor', 'total_prelims', 'key_items'],
        other_fees: ['subcontractor', 'description', 'amount', 'notes'],
        provisional_sums: ['subcontractor', 'description', 'amount', 'notes'],
      },
    },

    // Programme Analysis subsections
    programme_analysis: {
      subsectionOrder: ['lead_in_periods', 'overall_duration', 'site_visits'],
      columns: {
        lead_in_periods: [
          'subcontractor',
          'duration',
          'key_activities',
          'notes',
        ],
        overall_duration: [
          'subcontractor',
          'total_weeks',
          'resource_levels',
          'start_constraints',
          'end_constraints',
        ],
        site_visits: [
          'subcontractor',
          'number_of_visits',
          'duration',
          'purpose',
          'cost_implications',
        ],
      },
    },

    // Inclusions/Exclusions subsections
    inclusions_exclusions: {
      subsectionOrder: ['scope_differences', 'commercial_terms'],
      columns: {
        scope_differences: ['item', 'included_by', 'excluded_by', 'impact'],
        commercial_terms: ['term', 'variations', 'impact'],
      },
    },

    // Risks subsection
    risks: {
      subsectionOrder: ['risks'],
      columns: {
        risks: [
          'subcontractor',
          'risk_category',
          'description',
          'potential_impact',
          'mitigation_suggestion',
        ],
      },
    },

    // Opportunities subsection
    opportunities: {
      subsectionOrder: ['opportunities'],
      columns: {
        opportunities: [
          'subcontractor',
          'category',
          'description',
          'potential_benefit',
          'implementation_notes',
        ],
      },
    },

    // Clarifications subsection
    clarifications_required: {
      subsectionOrder: ['clarifications_required'],
      columns: {
        clarifications_required: [
          'subcontractor',
          'category',
          'query',
          'reason',
          'impact',
        ],
      },
    },
  },

  // Empty array placeholder texts by column name
  emptyArrayText: {
    included_by: 'None included',
    excluded_by: 'None excluded',
    strengths: 'No strengths specified',
    weaknesses: 'No weaknesses found',
    key_items: 'No key items listed',
    // Default text used when no specific match
    default: 'None',
  },

  // Define special rendering for certain columns (optional)
  columnRenderers: {
    strengths: (value) => (Array.isArray(value) ? value.join('\n') : value),
    weaknesses: (value) => (Array.isArray(value) ? value.join('\n') : value),
    key_activities: (value) =>
      Array.isArray(value) ? value.join('\n') : value,
  },
};
