import React from 'react';
import { Typography, Box } from '@mui/material';
import { Check } from 'lucide-react';

const RegistrationSuccess = () => {
  return (
    <Box
      className="w-full"
      sx={{
        backgroundColor: { xs: '#fff', sm: '#526269' },
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        className="w-full mx-4"
        sx={{
          maxWidth: '400px', // Matched to your sign-up form's maxWidth
          backgroundColor: '#fff',
          borderRadius: '8px',
          border: { xs: 0, sm: '1px solid #ccc' },
          padding: '32px 40px', // Matched to your sign-up form's padding
          marginY: { xs: '40px', sm: 0 },
        }}
      >
        <div className="flex justify-center mb-6">
          <div className="rounded-full bg-emerald-100 p-3">
            <Check className="w-10 h-10 text-emerald-500" />
          </div>
        </div>

        <Typography
          variant="h4"
          sx={{
            fontFamily: 'RooneySans',
            fontSize: '22px',
            fontWeight: 600,
            color: '#4CC0AD',
            textAlign: 'center',
            marginBottom: '24px',
          }}
        >
          Thank You for Registering!
        </Typography>

        <Typography
          sx={{
            fontFamily: 'RooneySans',
            fontSize: '16px',
            color: '#08202A',
            textAlign: 'center',
            marginBottom: '16px',
          }}
        >
          We&apos;ve sent a verification email to your inbox. Please check your
          email and follow the instructions to validate your account.
        </Typography>

        <Typography
          sx={{
            fontFamily: 'RooneySans',
            fontSize: '14px',
            color: '#526269',
            textAlign: 'center',
          }}
        >
          Haven&apos;t received the email? Please check your spam folder. The
          email should arrive within a few minutes.
        </Typography>
      </Box>
    </Box>
  );
};

export default RegistrationSuccess;
