// ListContent.jsx
import React from 'react';
import PropTypes from 'prop-types';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import renderSection from './renderSection';

const ListContent = ({ data, sectionKey }) => {
  return (
    <TableContainer component={Paper} sx={{ mb: 3 }}>
      {renderSection(data, sectionKey)}
    </TableContainer>
  );
};

// PropTypes for recursive data structure
const DataShape = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.object),
  PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ])
  ),
]);

ListContent.propTypes = {
  data: DataShape.isRequired,
  sectionKey: PropTypes.string,
};

export default ListContent;
